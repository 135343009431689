import React from 'react';
import { connect } from 'react-redux';
import MetaTags from 'react-meta-tags';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import YouTube from 'react-youtube';
import {
  Link
} from "react-router-dom";
import Languagealternate from '../Components/Languagealternate';
import styles from '../styles/app.module.css';

class Home extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {

    const { id, title, details, images, youtube, category, alternate, translations } = this.props
    const opts = {
      height: '800',
      width: '1200',
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
      },
    };
    return (
      <div>
        <div className={styles.content_wrapper}>
          <MetaTags>
            <title>{details.metatitle}</title>
            <meta name="description" content={details.description} />
            <meta property="og:title" content={details.metatitle} />
            <meta property="og:image" content={images.md} />
            <link rel="canonical" href={details.url} />
            {alternate.map(({ language, url }, index) => (
            <Languagealternate language={language} url={url} />
            ))}
          </MetaTags>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link className={styles.breadcrumb} color="inherit"  href={translations["home_url"]} to={translations["home_url"]}>
              {translations["home"]}
            </Link>
            <Link className={styles.breadcrumb} color="inherit" href={category.url} to={category.url}>
              {category.title}
            </Link>
            <Typography className={styles.breadcrumb} color="textPrimary">{details.metatitle}</Typography>
          </Breadcrumbs>
          <h1>{title}</h1>
          <div dangerouslySetInnerHTML={{__html: details.text}}></div>
          {(() => {
            if (youtube) {
              return (
                <div className={styles.youtube}>
                  <YouTube videoId={youtube} opts={opts} onReady={this._onReady} />
                </div>
              )
            } else {
              return null
            }
          })()}
        </div>
      </div>
    )
  }

  _onReady(event) {
    event.target.pauseVideo();
  }

}
const mapStateToProps = function(state) {
  return {
    language: state.paramsReducer.language,
    content: state.paramsReducer.content,
  }
}

export default connect(mapStateToProps)(Home);
