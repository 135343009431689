import React from 'react';
import { connect } from 'react-redux';
import MetaTags from 'react-meta-tags';
import { Translation } from 'react-i18next';
import styles from '../styles/app.module.css';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import 'pure-react-carousel/dist/react-carousel.es.css';
import '@brainhubeu/react-carousel/lib/style.css';
import {
  Link
} from "react-router-dom";

class Home extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true
    };
  }

  render() {
    const { language, data, translations } = this.props
    return (
      <div>
        <MetaTags>
          <title>{translations["home_title"]}</title>
          <meta name="description" content={translations["home_description"]} />
          <meta property="og:title" content={translations["home_title"]} />
        </MetaTags>
        <CarouselProvider className={styles.slider}
          naturalSlideWidth={1140}
          naturalSlideHeight={600}
          totalSlides={data.carousels['index'].length}
          infinite={true}
          isPlaying={true}
        >
          <Slider>
            {data.carousels['index'].map(({ title, image, webp }, index) => (
            <Slide index={index}>
              <div className={styles.slide}>
                <picture>
                  <source srcset={webp} type="image/webp" />
                  <source srcset={image} type="image/jpeg" />
                  <img className={styles.imgfluid} src={image} alt={title} />
                </picture>
                <div className={styles.slidetitle}>
                  <Typography variant="p" component="p" className={styles.slidetext}>{title}</Typography>
                </div>
              </div>
            </Slide>
            ))}
          </Slider>
        </CarouselProvider>
        <div className={styles.content_wrapper}>
          <h1>{ data.blocks['index'].title }</h1>
          <div dangerouslySetInnerHTML={{__html: data.blocks['index'].text}}></div>
        </div>
        <div className={styles.content_wrapper}>
          <Grid container spacing={3}>
            {data.categories
              .filter(function (cat) {
                return cat.header === true;
              })
              .sort(function (a, b) {
                return a.ordernumber - b.ordernumber;
              })
              .map(({ id, title, details, images, url }, index) => (
            <Grid item xs={12} md={6} lg={4}>
              <Link to={url}>
                <Card>
                  <CardMedia
                    className={styles.media}
                    image={images.md}
                    title={title}
                  />
                  <CardContent className={styles.box}>
                    <div className={styles.box}>
                      <Typography variant="h5" component="h3" className={styles.boxtitle} gutterBottom>
                        {title}
                      </Typography>
                      <Typography variant="p" component="p" className={styles.boxtext}>
                        {details.description}
                      </Typography>
                    </div>
                  </CardContent>
                  <CardActions>
                    <div className={styles.boxbutton}>
                      <Button variant="contained" color="secondary">{translations["learn_more"]}</Button>
                    </div>
                  </CardActions>
                </Card>
              </Link>
            </Grid>
            ))}
          </Grid>
        </div>
      </div>
    )
  }
}

const mapStateToProps = function(state) {
  return {
    language: state.paramsReducer.language,
    content: state.paramsReducer.content,
  }
}

export default connect(mapStateToProps)(Home);
