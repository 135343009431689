import React from 'react';
import { connect } from 'react-redux';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ToolsOne from '../Pages/ToolsOne';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

class Tools extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      value: 0,
    };
  }

  render() {
    const { translations } = this.props

    const handleChange = (event, newValue) => {
      this.setState({
        value: newValue,
      })
    };
    return (
      <div>
        <MetaTags>
          <title>{translations["tools_title"]}</title>
          <meta name="description" content={translations["tools_description"]} />
          <meta property="og:title" content={translations["tools_title"]} />
        </MetaTags>
        <AppBar position="static">
          <Tabs value={this.state.value} onChange={handleChange} aria-label="simple tabs example">
            <Tab label={translations["tools_1"]} />
            <Tab label={translations["tools_2"]} />
          </Tabs>
        </AppBar>
        <TabPanel value={this.state.value} index={0}>
          <ToolsOne {...this.props} />
        </TabPanel>
        <TabPanel value={this.state.value} index={1}>
          Item Two
        </TabPanel>
      </div>
    )
  }
}

const mapStateToProps = function(state) {
  return {
    language: state.paramsReducer.language,
    content: state.paramsReducer.content,
  }
}

export default connect(mapStateToProps)(Tools);
