import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import BuildIcon from '@material-ui/icons/Build';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import YouTubeIcon from '@material-ui/icons/YouTube';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import {
  Link
} from "react-router-dom";
import i18n from "i18next";
import {initReactI18next, useTranslation} from 'react-i18next';
import { useHistory } from "react-router-dom";

const drawerWidth = 340;
const drawerWidthMobile = 280;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: theme.zIndex.drawer,
  },
  drawerPaper: {
    width: drawerWidth,
    flexShrink: 0,
    background: 'linear-gradient(145deg, #D00 10%, #F00 90%)',
  },
  drawerPaperMobile: {
    width: drawerWidthMobile,
    flexShrink: 0,
    background: 'linear-gradient(145deg, #D00 10%, #F00 90%)',
  },
  drawerPaperContainer: {
    flexGrow: 1,
  },
  gridContainer: {
    flexGrow: 0,
    padding: theme.spacing(2),
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightLight,
  },
  linkicon: {
    padding: theme.spacing(1),
  },
  label: {
    fontWeight: theme.typography.fontWeightRegular,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  formControl: {
    minWidth: '100%',
    marginBottom: theme.spacing(2),
  },
  appBar: {
    background: 'linear-gradient(45deg, #D00 10%, #E00 90%)',
    zIndex: theme.zIndex.drawer + 1,
    maxWidth: '100vw',
    overflowX: 'hidden',
  },
}));

export default function NavBar(props) {
  const { window, categories, language, switchLang, translations } = props;
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDrawerClose = () => {
    setMobileOpen(false);
  };

  let history = useHistory();

  const [locale, setLocale] = React.useState(language);
  const handleChange = (event) => {
    switchLang(event.target.value)
    setLocale(event.target.value)
    history.push('/' + event.target.value)
    handleDrawerClose()
  };

  const drawer = (
    <div className={classes.drawerPaperContainer}>
      <List>
        <Link onClick={handleDrawerClose} className={classes.link} to={'/' + locale}>
          <ListItem button key="home">
            <ListItemIcon><HomeIcon color="primary" /></ListItemIcon>
            <ListItemText primary={translations["home"]} />
          </ListItem>
        </Link>
      </List>
      <Divider />
      <List>
        {categories.categories
          .filter(function (cat) {
            return cat.header === true;
          })
          .map(({ id, title, childrens, url }, index) => (
          <Link onClick={handleDrawerClose} className={classes.link} to={url}>
            <ListItem button key={id}>
              <ListItemIcon><KeyboardArrowRightIcon color="primary" /></ListItemIcon>
              <ListItemText primary={title} />
            </ListItem>
          </Link>
        ))}
      </List>
      <Divider />
      <List>
        <Link onClick={handleDrawerClose} className={classes.link} to={'/' + locale + '/tools'}>
          <ListItem button key="tools">
            <ListItemIcon><BuildIcon fontSize="small" color="primary" /></ListItemIcon>
            <ListItemText primary={translations["tools"]} />
          </ListItem>
        </Link>
        <Link onClick={handleDrawerClose} className={classes.link} to={'/' + locale + '/meetus'}>
          <ListItem button key="meetus">
            <ListItemIcon><CalendarTodayIcon fontSize="small" color="primary" /></ListItemIcon>
            <ListItemText primary={translations["meetus"]} />
          </ListItem>
        </Link>
      </List>
      <Divider />
    </div>
  );

  const social = (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-end"
    >
      <Link className={classes.linkicon} to="/linkedin">
        <LinkedInIcon color="primary" />
      </Link>
      <Link className={classes.linkicon} to="/facebook">
        <FacebookIcon color="primary" />
      </Link>
      <Link className={classes.linkicon} to="/">
        <YouTubeIcon color="primary" />
      </Link>
    </Grid>
  );

  const localeSwitch = (
    <Grid
      container
      className={classes.gridContainer}
      direction="row"
      justify="center"
      alignItems="flex-end"
      color="primary"
    >
      <FormControl className={classes.formControl}>
        <InputLabel className={classes.label} id="language-select-label">{translations["language"]}</InputLabel>
        <Select
          labelId="language-label"
          id="language-select"
          value={locale}
          onChange={handleChange}
        >
          <MenuItem className={classes.label} value="fr">Français</MenuItem>
          <MenuItem className={classes.label} value="it">Italian</MenuItem>
          <MenuItem className={classes.label} value="en">English</MenuItem>
          <MenuItem className={classes.label} value="es">Spanish</MenuItem>
        </Select>
      </FormControl>
      {social}
    </Grid>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden mdUp implementation="css">
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="primary"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
              <Typography color="primary" variant="h6" noWrap>
                IEServices
              </Typography>
            </Toolbar>
          </AppBar>
          <Drawer
            container={container}
            variant="temporary"
            anchor='left'
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaperMobile,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
            {localeSwitch}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
            {localeSwitch}
          </Drawer>
        </Hidden>
      </nav>
  );
}
