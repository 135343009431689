const API_TOKEN = "327ceaf2-5aa7-470a-acf3-269a7d374d2e";

export function getParams(language) {
  const url = 'https://new.ieservices.eu/react/categories?language=' + language
  return fetch(url)
    .then((response) => response.json())
    .catch((error) => console.error(error))
}

export function getProducts(idCategory) {
  const url = 'https://api.capsulecafe.fr/react/category_products/' + idCategory + '?api_key=' + API_TOKEN
  return fetch(url)
    .then((response) => response.json())
    .catch((error) => console.error(error))
}

export function getProductDetail(idProduct) {
  const url = 'https://api.capsulecafe.fr/react/product/' + idProduct + '?api_key=' + API_TOKEN
  return fetch(url)
    .then((response) => response.json())
    .catch((error) => console.error(error))
}

export function getImageFromApi (name) {
  return 'https://www.capsulecafe.com' + name
}

export function getImageFromUrl (name) {
  return name
}

export function checkEmailApi(email) {
  const url = 'https://api.capsulecafe.fr/react/user/check_email?api_key=' + API_TOKEN
  return fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: email }),
    })
    .then((response) => response.json())
    .catch((error) => console.error(error))
}

export function getLoginDetail(email, password) {
  const url = 'https://api.capsulecafe.fr/react/user/check?api_key=' + API_TOKEN
  return fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: email, password: password }),
    })
    .then((response) => response.json())
    .catch((error) => console.error(error))
}

export function createAccount(email, password, company, firstname, lastname, phone, isCompany, streetaddress, subaddress, postcode, city, isNews) {
  const url = 'https://api.capsulecafe.fr/react/user/create_user?api_key=' + API_TOKEN
  return fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email:          email,
        phone:          phone,
        password:       password,
        lastname:       lastname,
        firstname:      firstname,
        isCompany:      isCompany,
        company:        company,
        streetaddress:  streetaddress,
        subaddress:     subaddress,
        postcode:       postcode,
        city:           city,
        isNews:         isNews
      }),
    })
    .then((response) => response.json())
    .catch((error) => console.error(error))
}

export function getOrders(email, token) {
  const url = 'https://api.capsulecafe.fr/react/user/get_user_orders?api_key=' + API_TOKEN
  return fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: email, token: token }),
    })
    .then((response) => response.json())
    .catch((error) => console.error(error))
}

export function getOrderView(email, token, idOrder) {
  const url = 'https://api.capsulecafe.fr/react/user/get_user_order_view?api_key=' + API_TOKEN
  return fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: email, token: token, orderid: idOrder }),
    })
    .then((response) => response.json())
    .catch((error) => console.error(error))
}
