import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Hidden from '@material-ui/core/Hidden';
import Fab from '@material-ui/core/Fab';
import PhoneIcon from '@material-ui/icons/Phone';
import NavigationIcon from '@material-ui/icons/Navigation';
import logo from '../images/logo.png';

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: '#fff',
    maxWidth: '100vw',
    overflowX: 'hidden',
    height: '65px',
    paddingLeft: '340px',
    flexGrow: 1,
  },
  margin: {
    marginLeft: 'auto',
    marginRight: theme.spacing(1),
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontWeight: '600'
  },
  title: {
    flexGrow: 1,
  },
}));

export default function NavBar() {
  const classes = useStyles();

  return (
    <Hidden smDown implementation="css">
      <AppBar className={classes.appBar}>
        <Toolbar>
          <img width="160" src={logo} alt="Logo" />
          <Fab variant="extended" size="small" color="secondary" aria-label="phone" className={classes.margin}>
            <PhoneIcon />
            <Typography variant="h1" component="h4" className={classes.button}>
              0039-335-6983327
            </Typography>
          </Fab>
        </Toolbar>
      </AppBar>
    </Hidden>
  );
}
