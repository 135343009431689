import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import SettingsIcon from '@material-ui/icons/Settings';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    background: '#fff',
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '25ch',
  },
  container_button: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    margin: theme.spacing(3),
  },
  button: {
    fontWeight: 700,
    padding: theme.spacing(3),
  }
}));

export default function InputAdornments(props) {
  const classes = useStyles();
  const { translations } = props
  const [values, setValues] = React.useState({
    diameter: '88.9',
    thickness: '9',
    lenght: '3.05',
  });

  const [results, setResults] = React.useState({
    diameter_int: '',
    kgmt: '',
    tontot: '',
    kgtot: '',
    w2: '',
    sez: '',
  });

  function precisionRound(number, precision) {
	  var factor = Math.pow(10, precision);
	  return Math.round(number * factor) / factor;
	}

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleCalculate = () => {
    const diam_int = values.diameter - values.thickness - values.thickness;

    const resultat1 = ((values.diameter - values.thickness) * values.thickness * 3.14159 * 0.00786);
    const kgmt = precisionRound(resultat1, 2);

    var resultat2 = (values.lenght * resultat1 / 1000);
		var tontot = precisionRound(resultat2, 2);

    var resultat3 = (resultat2 * 1000);
		var kgtot = precisionRound(resultat3, 2);

    var resultat4 = (((values.diameter * values.diameter * values.diameter * values.diameter - diam_int * diam_int * diam_int * diam_int) / values.diameter) * 0.09812 / 1000);
		var w2 = precisionRound(resultat4, 2);

    var resultat5 = ((((values.diameter * values.diameter * 3.14) - (diam_int * diam_int * 3.14)) / 4) / 100);
		var sez = precisionRound(resultat5, 2);

    setResults({ ...results, ['diameter_int']: diam_int, ['kgmt']: kgmt, ['tontot']: tontot, ['kgtot']: kgtot, ['w2']: w2, ['sez']: sez });
  };

  return (
  <div>
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={4}>
          <FormControl fullWidth className={classes.margin} autoComplete="off" variant="outlined">
            <InputLabel color="secondary"  htmlFor="outlined-adornment-amount">{translations["tools_diameter_ext"]}</InputLabel>
            <OutlinedInput
              fullWidth
              autoComplete="off"
              margin="normal"
              id="outlined-adornment-amount"
              value={values.diameter}
              onChange={handleChange('diameter')}
              labelWidth={100}
              color="secondary"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <FormControl fullWidth className={classes.margin} autoComplete="off" variant="outlined">
            <InputLabel color="secondary"  htmlFor="outlined-adornment-amount">{translations["tools_thickness"]}</InputLabel>
            <OutlinedInput
              fullWidth
              autoComplete="off"
              margin="normal"
              id="outlined-adornment-amount"
              value={values.thickness}
              onChange={handleChange('thickness')}
              labelWidth={100}
              color="secondary"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <FormControl fullWidth className={classes.margin} autoComplete="off" variant="outlined">
            <InputLabel color="secondary"  htmlFor="outlined-adornment-amount">{translations["tools_lenght"]}</InputLabel>
            <OutlinedInput
              fullWidth
              autoComplete="off"
              margin="normal"
              id="outlined-adornment-amount"
              value={values.lenght}
              onChange={handleChange('lenght')}
              labelWidth={100}
              color="secondary"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
    </div>
    <div className={classes.container_button}>
      <Fab variant="extended" color="secondary" aria-label="phone" onClick={() => { handleCalculate() }} className={classes.margin}>
        <SettingsIcon />
        <Typography variant="h1" component="h2" className={classes.button}>
          {translations["tools_calculate"]}
        </Typography>
      </Fab>
    </div>
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={4}>
          <FormControl fullWidth className={classes.margin} autoComplete="off" variant="outlined">
            <InputLabel color="secondary"  htmlFor="outlined-adornment-amount">{translations["tools_lenght"]}</InputLabel>
            <OutlinedInput
              fullWidth
              autoComplete="off"
              margin="normal"
              id="outlined-adornment-amount"
              value={results.diameter_int}
              labelWidth={100}
              color="secondary"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              disabled
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <FormControl fullWidth className={classes.margin} autoComplete="off" variant="outlined">
            <InputLabel color="secondary"  htmlFor="outlined-adornment-amount">{translations["tools_kgmt"]}</InputLabel>
            <OutlinedInput
              fullWidth
              autoComplete="off"
              margin="normal"
              id="outlined-adornment-amount"
              value={results.kgmt}
              labelWidth={100}
              color="secondary"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              disabled
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <FormControl fullWidth className={classes.margin} autoComplete="off" variant="outlined">
            <InputLabel color="secondary"  htmlFor="outlined-adornment-amount">{translations["tools_tontot"]}</InputLabel>
            <OutlinedInput
              fullWidth
              autoComplete="off"
              margin="normal"
              id="outlined-adornment-amount"
              value={results.tontot}
              labelWidth={100}
              color="secondary"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              disabled
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <FormControl fullWidth className={classes.margin} autoComplete="off" variant="outlined">
            <InputLabel color="secondary"  htmlFor="outlined-adornment-amount">{translations["tools_kgtot"]}</InputLabel>
            <OutlinedInput
              fullWidth
              autoComplete="off"
              margin="normal"
              id="outlined-adornment-amount"
              value={results.kgtot}
              labelWidth={100}
              color="secondary"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              disabled
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <FormControl fullWidth className={classes.margin} autoComplete="off" variant="outlined">
            <InputLabel color="secondary"  htmlFor="outlined-adornment-amount">{translations["tools_w2"]}</InputLabel>
            <OutlinedInput
              fullWidth
              autoComplete="off"
              margin="normal"
              id="outlined-adornment-amount"
              value={results.w2}
              labelWidth={100}
              color="secondary"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              disabled
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <FormControl fullWidth className={classes.margin} autoComplete="off" variant="outlined">
            <InputLabel color="secondary"  htmlFor="outlined-adornment-amount">{translations["tools_sez"]}</InputLabel>
            <OutlinedInput
              fullWidth
              autoComplete="off"
              margin="normal"
              id="outlined-adornment-amount"
              value={results.sez}
              labelWidth={100}
              color="secondary"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              disabled
            />
          </FormControl>
        </Grid>
      </Grid>
    </div>
  </div>
  );
}
