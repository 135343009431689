import React from 'react';
import { connect } from 'react-redux';
import NavBar from './Structure/NavBar';
import Drawer from './Structure/Drawer';
import { getParams } from './Api/RestApi'
import Home from './Pages/Home';
import Category from './Pages/Category';
import Article from './Pages/Article';
import Tools from './Pages/Tools';
import Meetus from './Pages/Meetus';
import Contact from './Contact';
import Divider from '@material-ui/core/Divider';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import styles from './styles/app.module.css';
import i18n from "i18next";
import {initReactI18next, useTranslation} from 'react-i18next';

class Language extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      language: i18n.language
    };
    this.switchLang = this.switchLang.bind(this)
  }

  loadParams(language) {
    getParams(language).then(data => {
      const action = { type: "TOGGLE_CONTENT", value: data }
      this.props.dispatch(action)
      this.setState({
        data: data,
        isLoading: false,
      })
      console.log('Lang ' + language + ' charged')
    })
  }

  switchLang(lang) {
    const action = { type: "TOGGLE_LANGUAGE", value: lang }
    this.props.dispatch(action)
    i18n.use(initReactI18next).init({lng: lang});
    this.loadParams(lang);
  }

  componentDidMount(){
    this.loadParams(i18n.language);
  }

  render() {
    if (this.state.isLoading) {
      return null
    } else {
      return (
        <Router>
          <div className={styles.grid}>
            <Drawer switchLang={this.switchLang} language={this.state.language} categories={this.state.data} translations={this.state.data.translations} />
            <div className={styles.content}>
              <NavBar />
              <Switch>
                <Route path='/linkedin' component={() => {
                  window.location.href = 'https://www.linkedin.com/in/nicolas-cappelli-14389620/';
                  return null;
                }}/>
                <Route path='/facebook' component={() => {
                  window.location.href = 'https://www.facebook.com/IEServices-335390720308134/';
                  return null;
                }}/>
                <Route exact path="/" component={() => {
                  window.location.href = 'https://www.ieservices.eu/en';
                  return null;
                }}/>
                <Route exact path="/:lang/">
                  <Home translations={this.state.data.translations} data={this.state.data} language={i18n.language} />
                </Route>
                {this.state.data.categories.map(({ id, title, childrens, url, details, cms, images, alternate, products }, index) => (
                <Route path={url}>
                  <Route exact path={url}>
                    <Category id={id} title={title} details={details} childrens={childrens} cms={cms} products={products} images={images} alternate={alternate} translations={this.state.data.translations} categories={this.state.data.categories} />
                  </Route>
                  {childrens.map(({ id, title}, index) => (
                  this.state.data.categories
                    .filter(function (cat) {
                      return cat.id === id;
                    })
                    .map(({ id, title, childrens, url, details, cms, images, alternate, products }, index) => (
                    <Route exact path={url}>
                      <Category id={id} title={title} details={details} childrens={childrens} cms={cms} products={products} images={images} alternate={alternate} translations={this.state.data.translations} categories={this.state.data.categories} />
                    </Route>
                  ))
                  ))}
                  {cms.map(({ id, title, childrens, url, details, cms, images, youtube, category, alternate }, index) => (
                  <Route exact path={url}>
                    <Article id={id} title={title} details={details} images={images} youtube={youtube} category={category} alternate={alternate} translations={this.state.data.translations} />
                  </Route>
                  ))}
                </Route>
                ))}
                <Route exact path="/:lang/tools">
                  <Tools translations={this.state.data.translations}  />
                </Route>
                <Route exact path="/:lang/meetus">
                  <Meetus meetus={this.state.data.meetus} translations={this.state.data.translations}  />
                </Route>
              </Switch>
            </div>
          </div>
        </Router>
      )
    }
  }
}

const mapStateToProps = function(state) {
  return {
    language: state.paramsReducer.language,
    content: state.paramsReducer.content,
  }
}

export default connect(mapStateToProps)(Language);
