import React from 'react';
import { connect } from 'react-redux';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ToolsOne from '../Pages/ToolsOne';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import Moment from 'react-moment';
import styles from '../styles/app.module.css';

class Tools extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      value: 0,
    };
  }

  render() {
    const { translations, meetus } = this.props

    return (
      <div>
        <MetaTags>
          <title>{translations["meetus_title"]}</title>
          <meta name="description" content={translations["meetus_description"]} />
          <meta property="og:title" content={translations["meetus_title"]} />
        </MetaTags>
        <VerticalTimeline>
        {meetus
          .sort(function (a, b) {
            return b.startAt - a.startAt;
          })
          .map(({ title, text, startAt, endAt, street, city, country }, index) => (
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: '#d00', color: '#fff' }}
            contentArrowStyle={{ borderRight: '7px solid  #d00' }}
            date=<div className={styles.breadcrumb}><Moment format="DD/MM/YYYY" date={startAt} /> - <Moment format="DD/MM/YYYY" date={endAt} /></div>
            iconStyle={{ background: '#d00', color: '#fff' }}
          >
            <h3 className="vertical-timeline-element-title" dangerouslySetInnerHTML={{__html: title }}></h3>
            <h4 className="vertical-timeline-element-subtitle">{ street } { city } { country }</h4>
            <p>
              <div dangerouslySetInnerHTML={{__html: text }}></div>
            </p>
          </VerticalTimelineElement>
          ))}
        </VerticalTimeline>
      </div>
    )
  }
}

const mapStateToProps = function(state) {
  return {
    language: state.paramsReducer.language,
    content: state.paramsReducer.content,
  }
}

export default connect(mapStateToProps)(Tools);
