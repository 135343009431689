import React from 'react';
import { connect } from 'react-redux';
import NavBar from './Structure/NavBar';
import Drawer from './Structure/Drawer';
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import {initReactI18next, useTranslation} from 'react-i18next';
import { getParams } from './Api/RestApi'
import Language from './Language';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import styles from './styles/app.module.css';

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  detectLang() {
    if(document.URL.indexOf("/fr") >= 0) {
      i18n.use(initReactI18next).init({lng: 'fr'});
      const action = { type: "TOGGLE_LANGUAGE", value: 'fr' }
      this.props.dispatch(action)
      this.setState({
        language: 'fr',
        isLoading: false,
      })
    } else if(document.URL.indexOf("/es") >= 0) {
      i18n.use(initReactI18next).init({lng: 'es'});
      const action = { type: "TOGGLE_LANGUAGE", value: 'es' }
      this.props.dispatch(action)
      this.setState({
        language: 'es',
        isLoading: false,
      })
    } else if(document.URL.indexOf("/it") >= 0) {
      i18n.use(initReactI18next).init({lng: 'it'});
      const action = { type: "TOGGLE_LANGUAGE", value: 'it' }
      this.props.dispatch(action)
      this.setState({
        language: 'it',
        isLoading: false,
      })
    } else {
      i18n.use(initReactI18next).init({lng: 'en'});
      const action = { type: "TOGGLE_LANGUAGE", value: 'en' }
      this.props.dispatch(action)
      this.setState({
        language: 'en',
        isLoading: false,
      })
    }
  }

  componentDidMount(){
    this.detectLang();
  }

  render() {
    if (this.state.isLoading) {
      return null
    } else {
      return (
      <I18nextProvider i18n={i18n}>
        <Router>
          <Switch>
            <Route
              path="/:lang/"
              render={props =>
                <Language lang={this.state.language} {...props} />
              }
            />
          </Switch>
        </Router>
      </I18nextProvider>
      )
    }
  }
}

const mapStateToProps = function(state) {
  return {
    language: state.language,
    content: state.content,
  }
}

export default connect(mapStateToProps)(App);
