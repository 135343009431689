import React from 'react';
import { connect } from 'react-redux';
import MetaTags from 'react-meta-tags';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Languagealternate from '../Components/Languagealternate';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {
  Link
} from "react-router-dom";
import styles from '../styles/app.module.css';

class Home extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    const { id, title, details, childrens, cms, images, alternate, translations, categories, products } = this.props
    console.log(products)
    return (
      <div>
        <div className={styles.content_wrapper}>
          <MetaTags>
            <title>{details.metatitle}</title>
            <meta name="description" content={details.description} />
            <meta property="og:title" content={details.metatitle} />
            <meta property="og:image" content={images.md} />
            <link rel="canonical" href={details.url} />
            {alternate.map(({ language, url }, index) => (
            <Languagealternate language={language} url={url} />
            ))}
          </MetaTags>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link className={styles.breadcrumb} color="inherit"  href={translations["home_url"]} to={translations["home_url"]}>
              {translations["home"]}
            </Link>
            <Typography color="textPrimary">{title}</Typography>
          </Breadcrumbs>
          <h1>{title}</h1>
          <div dangerouslySetInnerHTML={{__html: details.text}}></div>
        </div>
        <div className={styles.content_wrapper}>
          <Grid container spacing={3}>
            {childrens.map(({ id, title}, index) => (
            <Grid item xs={12} md={6} lg={4}>
            {categories
              .filter(function (cat) {
                return cat.id === id;
              })
              .sort(function (a, b) {
                return a.ordernumber - b.ordernumber;
              })
              .map(({ id, title, details, images, url, parent }, index) => (
              <Link to={url}>
                <Card>
                  <CardMedia
                    className={styles.media}
                    image={images.md}
                    title={title}
                  />
                  <CardContent className={styles.box}>
                    <div className={styles.box}>
                      <Typography variant="h5" component="h3" className={styles.boxtitle} gutterBottom>
                        {title}
                      </Typography>
                      <Typography variant="p" component="p" className={styles.boxtext}>
                        {details.description}
                      </Typography>
                    </div>
                  </CardContent>
                  <CardActions>
                    <div className={styles.boxbutton}>
                      <Button variant="contained" color="secondary">{translations["learn_more"]}</Button>
                    </div>
                  </CardActions>
                </Card>
              </Link>
            ))}
            </Grid>
            ))}
            {cms
              .sort(function (a, b) {
                return a.ordernumber - b.ordernumber;
              })
              .map(({ id, title, details, images, url, parent }, index) => (
            <Grid item xs={12} md={6} lg={4}>
              <Link to={url}>
                <Card>
                  <CardMedia
                    className={styles.media}
                    image={images.md}
                    title={title}
                  />
                  <CardContent className={styles.box}>
                    <div className={styles.box}>
                      <Typography variant="h5" component="h3" className={styles.boxtitle} gutterBottom>
                        {title}
                      </Typography>
                      <Typography variant="p" component="p" className={styles.boxtext}>
                        {details.description}
                      </Typography>
                    </div>
                  </CardContent>
                  <CardActions>
                    <div className={styles.boxbutton}>
                      <Button variant="contained" color="secondary">{translations["learn_more"]}</Button>
                    </div>
                  </CardActions>
                </Card>
              </Link>
            </Grid>
            ))}
            {products
              .sort(function (a, b) {
                return a.ordernumber - b.ordernumber;
              })
              .map(({ id, title, images, brand, atts }, index) => (
            <Grid item xs={12} md={6} lg={4}>
                <Card>
                  <CardMedia
                    className={styles.media}
                    image={images.md}
                    title={title}
                  />
                  <CardContent className={styles.box}>
                    <div className={styles.box}>
                      <Typography variant="h6" component="h3" className={styles.boxtitle} gutterBottom>
                        {title}
                      </Typography>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                {translations["manufacturer"]}
                              </TableCell>
                              <TableCell align="right">
                                {brand}
                              </TableCell>
                            </TableRow>
                            {atts
                              .map(({ title, value }, index) => (
                            <TableRow>
                              <TableCell component="th" scope="row">
                                {title}
                              </TableCell>
                              <TableCell align="right">
                                {value}
                              </TableCell>
                            </TableRow>
                            ))}
                          </TableBody>
                        </Table>

                    </div>
                  </CardContent>
                </Card>
            </Grid>
            ))}
          </Grid>
        </div>
      </div>
    )
  }
}

const mapStateToProps = function(state) {
  return {
    language: state.paramsReducer.language,
    content: state.paramsReducer.content,
  }
}

export default connect(mapStateToProps)(Home);
