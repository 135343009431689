import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector).init({
  resources: {
    en: {
      translations: {
        home: "En Anglais",
      }
    },
    it: {
      translations: {
        home: "En Italien",
      }
    },
    es: {
      translations: {
        home: "En Espagnol",
      }
    },
    fr: {
      translations: {
        home: "En Francais",
      }
    }
  },
  fallbackLng: "en",
  debug: true,
  supportedLngs: ['en', 'fr', 'it', 'es'],
  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ","
  },

  react: {
    wait: true
  }
});

export default i18n;
